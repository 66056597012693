import { Navbar } from './components';
import { Header, About, Menu, Contact, Footer } from './containers';
import './App.css';

function App() {
    return (
        <>
            <Navbar />
            <Header />
            <About />
            <Menu />
            <Contact />
            <Footer />
        </>
    )
}

export default App;