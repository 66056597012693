import cactus1 from '../assets/cactus1.png';
import cactus2 from '../assets/cactus2.png';
import cactus3 from '../assets/cactus3.png';
import cactus4 from '../assets/cactus4.png';
import dip from '../assets/dip.jpg';
import dipSm from '../assets/dipSm.jpg';
import logoOrange from '../assets/logoOrange.png';
import logoPink from '../assets/logoPink.png';
import prep from '../assets/prep.jpg';
import prepSm from '../assets/prepSm.jpg';
import tacos from '../assets/tacos.jpg';
import tacosSm from '../assets/tacosSm.jpg';
import prep2 from '../assets/prep2.jpg';

export default {
    cactus1,
    cactus2,
    cactus3,
    cactus4,
    dip,
    dipSm,
    logoOrange,
    logoPink,
    prep,
    prepSm,
    tacos,
    tacosSm,
    prep2,
};